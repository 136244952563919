import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Merriweather Sans Light";
        src: url("/fonts/MerriweatherSans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Merriweather Sans Bold";
        src: url("/fonts/MerriweatherSans-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Ubuntu Regular";
        src: url("/fonts/Ubuntu-Regular.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Merriweather Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
        background-image: url("/img/svg/backgroundblue.png");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            box-shadow: #343F4B 0px 0px 0px 2px;
        }
    }

    h1,
    h6 {
        font-family: 'Merriweather Sans Bold', serif;
        color: #FFFFFF;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }
    h5{
        font-family: 'Ubuntu Regular';
        color: #FFFFFF;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }
    h2{
        font-family: 'Merriweather Sans Light', serif;
        color: #343F4B;
        font-size: 16px;
        line-height: 1.18;
        display: inline

        @media only screen and (max-width: 890px) {
          font-size: 15px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 14px;
        }
    }
    h3{
        font-family: 'Merriweather Sans Light', serif;
        color: #343F4B;
        font-size: 24px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 22px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 17px;
        }
    }
    h4{
        font-family: 'Merriweather Sans Bold', serif;
        color: #343F4B;
        font-size: 30px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 26px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 23px;
        }
    }

    p {
        color: #FFFFFF;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
