import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("waitlist")}
        >
          {/*<Button color="#53c6d8">
            <Span style={{ color: "#FFFFFF" }}>
              {t("Unete a la comunidad")}
              </Span>
          </Button>*/}

          
        </CustomNavLinkSmall>
      </>
    );
  };

  const MenuItemDrawer = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("waitlist")}
        >
          <Button color="#53c6d8">
            <Span style={{ color: "#FFFFFF" }}>
              {t("Unete a la comunidad")}
              </Span>
          </Button>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
          
        <Row justify="space-between" align="middle">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logoPNG.png" width="202px" height="" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          {/*<Burger onClick={showDrawer}>
            <Outline />
          </Burger>*/}
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu style={{ color: "#53c6d8"}}>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItemDrawer />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
