import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#343F4B"};
  color: ${(p) => (p.color ? "#fff" : "#fff")};
  font-color: #fff;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #53c6d8;
  border-radius: 15px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 20px rgba(23, 31, 114, 0.5), 0 0 10px rgba(0, 0, 0, 0.3),
              0 0 60px rgba(23, 31, 114, 0.4), 0 0 100px rgba(23, 31, 114, 0.2);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid rgb(25, 25, 25);
    background-color: rgb(25, 25, 25);
  }
`;
