import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="45px" height="45px" />
      </a>
    );
  };

  return (
    <>
      <Extra>
        <Container border={false} >
          <Row
            justify="space-between"
            style={{ paddingTop: "14rem" }}
          >
            <Col lg={4} md={12} sm={12} xs={12}>
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </LogoContainer>
              </NavLink>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <FooterContainer >
                <SocialLink
                  href="https://instagram.com/fluenty.app"
                  src="instagramLogo.png"
                />
              </FooterContainer>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <LogoContainer>
                <Para style={{ textShadow: "#000000 2px 0 8px" }}>
                    {t(`Fluenty 2024. Todos los derechos reservados`)}
                </Para>
              </LogoContainer>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
